const Grapnel = require('grapnel');
const _ = require('underscore');
const BCImageLoader = require('bc-image-loader');

const $ = require('jquery');
global.jQuery = $;
const Spinner = require('spin.js').Spinner;

// Initialize all global stuff with data from back-end
const { initGlobals } = require('../../common/js/commonSetup');
initGlobals();

const { getSortMethod, handleSorting } = require('../../common/js/sorting');
const { videoFinisher } = require('../../common/js/videofinisher');
const { setupPlayers } = require('../../common/js/perform');
const utils = require('../../common/js/utils');

const LARGE = 1200;
const MEDIUM = 992;
const SMALL = 768;
const EXTRA_SMALL = 1;
const VIDEO_ASPECT_RATIO = 16 / 9;
const VIDEOS_PER_PAGE = 15;
const BREAKPOINT_MOBILE = 480;
const READMORE_HEIGHT = 70;

function getLastNumberFromURL (url) {
  const regex = /\/(\d+)$/;
  const match = url.match(regex);

  return match ? parseInt(match[1], 10) : null;
}

class BCLSP1 {
  constructor () {
    this.scrollbarWidth = 0; // detected on document ready using "setScrollbarWidth"
    this.responsiveSize = null;
    this.templates = {};
  }

  determineScrollbarWidth () {
    const scrollDiv = document.createElement('div');
    scrollDiv.className = 'scrollbar-detect';
    document.body.appendChild(scrollDiv);

    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);

    return scrollbarWidth;
  }

  setScrollbarWidth (width) {
    this.scrollbarWidth = width;
  }

  determineResponsiveSize () {
    const windowWidth = $(window).width() + this.scrollbarWidth;

    if (windowWidth >= LARGE) {
      return LARGE;
    } else if (windowWidth >= MEDIUM) {
      return MEDIUM;
    } else if (windowWidth >= SMALL) {
      return SMALL;
    }

    return EXTRA_SMALL;
  }

  onWindowResize () {
    const newResponsiveSize = this.determineResponsiveSize();
    this.responsiveSize = newResponsiveSize;
    this.onWindowResizeAlways(newResponsiveSize);
    this.updateReadMore();
    this.updateMobileModals();
  }

  updateMobileModals () {
    if (!this.isMobile()) {
      $('#nav-modal').modal('hide');
    }
  }
  onWindowResizeAlways (responsiveSize) {
    this.resizeNavWithMore();
    this.resizeVideo(responsiveSize);
  }

  resizeNavWithMore () {
    const $navWithMore = $('.bc-nav-with-more');
    const $navContainer = $('.navbar[role=navigation] > .container');
    const $navbarHeader = $navContainer.find('.navbar-header');
    const $moreDropdown = $navWithMore.find('.dropdown-more');
    const $moreDropdownMenu = $moreDropdown.find('.dropdown-menu');
    const $searchDropdown = $navWithMore.find('.dropdown-search');
    const currentTheme = $navWithMore.attr('data-bc-theme');
    let availableWidth = -1;// will be calculated below

    switch (currentTheme) {
      case 'entertainment_responsive':
      case 'highend_responsive':
        availableWidth = $navWithMore.width() - ($moreDropdown.outerWidth() + 40);
        break;

      case 'marquee_entertainment': // Astral in Marquee v2
      case 'marquee_highend':       // Skyline in Marquee v2
        availableWidth = $navContainer.innerWidth() - ($searchDropdown.outerWidth() + $moreDropdown.outerWidth() + 140);
        break;

      default:
        availableWidth = $navContainer.innerWidth() - ($navbarHeader.outerWidth() + $searchDropdown.outerWidth() + $moreDropdown.outerWidth() + 40);
        break;
    }

    const dropdownItems = [];
    $navWithMore.children('li').each(function () {
      if (!$(this).hasClass('dropdown')) {
        dropdownItems.push($(this).remove());
      }
    });

    $moreDropdownMenu.children('li').each(function () {
      dropdownItems.push($(this).remove());
    });

    let $dropdownItem = null;

    if (this.responsiveSize !== EXTRA_SMALL) {// resize for non-mobile
      let usedWidth = 0;
      for (let i = 0; i < dropdownItems.length; i++) {
        $dropdownItem = dropdownItems[i];
        $dropdownItem.insertBefore($moreDropdown);

        usedWidth += $dropdownItem.outerWidth();

        if (usedWidth >= availableWidth) {
          $dropdownItem.remove().appendTo($moreDropdownMenu);
        }
      }
    } else { // based on count, not width on collapsed
      const maxCount = 4;

      for (let j = 0; j < dropdownItems.length; j++) {
        $dropdownItem = dropdownItems[j];

        if (j < maxCount) {
          $dropdownItem.insertBefore($moreDropdown);
        } else {
          $dropdownItem.appendTo($moreDropdownMenu);
        }
      }
    }

    if ($moreDropdownMenu.children().length === 0) {
      $moreDropdown.hide();
    } else {
      $moreDropdown.show();
    }
  }

  resizeVideo (responsiveSize) {
    const $container = $('.partial-video');

    if ($container.hasClass('partial-video-standard')) {
      this.resizeVideoStandard($container, responsiveSize);
    } else if ($container.hasClass('partial-video-fma-column')) {
      this.resizeVideoFmaColumn($container, responsiveSize);
    } else if ($container.hasClass('partial-video-large')) {
      this.resizeVideoLarge($container, responsiveSize);
    } else if ($container.hasClass('partial-video-short-fma')) {
      this.resizeVideoShortFma($container, responsiveSize);
    }
  }

  resizeVideoStandard ($container, responsiveSize) {
    const $categoryEditor = $('.partial-video .category-editor');
    const $videoWrapper = $container.find('.bc-video-wrapper');
    const $videoInfoWrapper = $container.find('.bc-video-info-wrapper');

    const containerWidth = $container.width();

    switch (responsiveSize) {
      case LARGE:
        $videoWrapper.width(622);
        $categoryEditor.width(622);
        $videoInfoWrapper.width(containerWidth - (622 + 20));
        break;

      default:
        //increasing the size of the main video on mobile to cover 10px padding
        $videoWrapper.css('margin-left', '-10px');
        $videoWrapper.width(containerWidth + 20);
        $categoryEditor.width(containerWidth + 20);
        $videoInfoWrapper.width(containerWidth - 10);
        break;
    }
  }

  resizeVideoFmaColumn ($container, responsiveSize) {
    const $categoryEditor = $('.partial-video .category-editor');
    const $fmaContent = $container.find('.bc-fma-content-wrapper');
    const $videoContent = $container.find('.bc-video-content-wrapper');
    const $videoInfo = $videoContent.find('.bc-video-info-wrapper');
    const $video = $videoContent.find('.bc-video-wrapper');

    const containerWidth = $container.width();
    const fmaContentWidth = $fmaContent.width();
    let videoContentWidth = -1;// to be calculated below

    switch (responsiveSize) {
      case LARGE:
        videoContentWidth = containerWidth - (fmaContentWidth + 20);// 20 = padding-left on fma column

        $videoContent.width(videoContentWidth);
        $videoInfo.width(videoContentWidth);
        $video.width(videoContentWidth);
        $video.height(Math.round(videoContentWidth / VIDEO_ASPECT_RATIO));
        $categoryEditor.width(videoContentWidth);
        $categoryEditor.height(Math.round(videoContentWidth / VIDEO_ASPECT_RATIO));
        $fmaContent.width('auto');
        break;

      default:
        $fmaContent.width('100%');
        //increasing the size of the main video on mobile to cover 10px padding
        $video.css('margin-left', '-10px');
        $video.width(containerWidth + 20);
        $categoryEditor.width(containerWidth + 20);
        $videoInfo.width(containerWidth);
        $video.height('');
        break;
    }
  }

  resizeVideoShortFma ($container, responsiveSize) {
    const $categoryEditor = $('.partial-video .category-editor');
    const $fmaContent = $container.find('.bc-fma-content-wrapper');
    const $videoInfo = $container.find('.bc-video-info-wrapper');
    const $video = $container.find('.bc-video-wrapper');

    const containerWidth = $container.width();
    const fmaContentWidth = $fmaContent.width();
    let videoWidth = -1;// to be calculated below

    switch (responsiveSize) {
      case LARGE:
        $videoInfo.width(fmaContentWidth);

        videoWidth = containerWidth - (fmaContentWidth + 20);// 20 = padding-left on fma column

        $video.width(videoWidth);
        $video.height(Math.round(videoWidth / VIDEO_ASPECT_RATIO));
        $categoryEditor.width(videoWidth);
        $categoryEditor.height(Math.round(videoWidth / VIDEO_ASPECT_RATIO));
        break;

      default:
        //increasing the size of the main video on mobile to cover 10px padding
        $video.css('margin-left', '-10px');
        $video.width(containerWidth + 20);
        $categoryEditor.width(containerWidth + 20);
        $videoInfo.width(containerWidth - 20);
        $video.height('');
        break;
    }
  }

  resizeVideoLarge ($container, responsiveSize) {
    const $categoryEditor = $('.partial-video .category-editor');
    const containerWidth = $container.width();
    const $video = $container.find('.bc-video-wrapper');
    switch (responsiveSize) {
      case LARGE:
      case MEDIUM:
        $video.width(containerWidth);
        $video.height(containerWidth / VIDEO_ASPECT_RATIO);
        $categoryEditor.width(containerWidth);
        $categoryEditor.height(containerWidth / VIDEO_ASPECT_RATIO);
        break;
      default:
        //increasing the size of the main video on mobile to cover 10px padding
        $video.css('margin-left', '-10px');
        $video.width(containerWidth + 20);
        $categoryEditor.width(containerWidth + 20);
        $video.height('');
        break;
    }

  }

  resizeHomepageGrid () {
    if ($('.bc-categories').length <= 0) {
      return;
    }

    $('.bc-category-block-image').each(function () {
      const $inner = $(this).children('.bc-category-block-image-inner');
      $(this).height(Math.floor($inner.innerWidth() / VIDEO_ASPECT_RATIO) + 3);// 3 compensates for padding
    });
  }

  loadTemplate (url, callback) {
    if (this.templates[url]) {
      return callback(this.templates[url]);
    }
    const self = this;
    $.ajax({
      url: url,
      success: function (contents) {
        self.templates[url] = window.bcGallery.Handlebars.compile(contents);
        callback(self.templates[url]);
      },
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  loadVideos (params, isCategoryUrl = false) {
    //Hide all mobile modals
    $('.mobile-search-modal').modal('hide');
    $('.navbar-ex1-modal').modal('hide');
    if ($('#collapseCustom').is(':visible')) {
      $('#collapseCustom').collapse('hide');
      $('.custom-header-mobile').addClass('collapsed');
    }
    $('.bc-video-grid-items').fadeTo(0, 0.3);

    this.spinner = new Spinner({ top: '45%' }).spin($('.bc-video-grid-items')[0]);

    // reset but not everything so as to not break autoplay next
    if (window.query) {
      window.query.page = null;
      window.query.q = null;
    }

    let route = '';

    // recursively loop through categories to find active category
    function recurse (category) {
      if (category.slug === params.category) {
        window.category = category;
        route = '/' + category.slug;
        return;
      }

      if (_.has(category, 'children')) {
        _.each(category.children, function (child) {
          recurse(child);
        });
      }
    }

    if (params.category) {
      _.each(window.categories, function (category) {
        recurse(category);
      });
    }

    if (params.page) {
      window.query.page = params.page;
    }

    if (params.q) {
      window.query.q = params.q;
      //If we're returning search results, hide main window on mobile
      if (this.isMobile()) {
        $('.partial-video').hide();
      }
    } else {
      $('.partial-video').show();
    }

    if (params.sort_by) {
      window.query.sort_by = params.sort_by;
    } else {
      const sortMethod = getSortMethod();
      params.sort_by = sortMethod;
    }
    const self = this;

    if (isCategoryUrl && window.location.hash && !params.page) {
      params.page = getLastNumberFromURL(window.location.href);
    }

    return $.ajax(window.baseUrl + '/api/videos' + route, {
      dataType: 'json',
      data: params,
      success: function (data) {
        if (params.append) {
          self.appendVideos(data);
        } else {
          self.updateVideoBrowser(data);
        }
      },
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  loadMoreVideos () {
    const nextPage = $('.bc-load-more-button').data('next-page');
    const match = /page=([a-z0-9]*)/i.exec(nextPage);
    let page;
    if (match && match.length > 0) {
      page = match[1];
    } else {
      page = Math.floor($('.bc-video-grid-items').children().length / VIDEOS_PER_PAGE);
    }
    const q = window.query ? window.query.q : null;
    const category = window.category ? window.category.slug : '';
    this.loadVideos({ append: true, page: page, q: q, category: category });
    delete window.query.page;
  }

  updateVideoBrowser (data) {
    if (!data || !data.result) {
      return;
    }

    const self = this;

    const videoGridData = {
      site: window.site,
      baseUrl: window.baseUrl,
      imageTranscoder: window.BCLS.imageTranscoder,
      category: window.category,
      videos: data.result,
      query: window.query,
      translations: window.translations,
      sorting: window.sorting,
      subPath: window.subPath,
    };

    self.loadTemplate(window.bcGallery.getTemplatePath('/sites/marquee-v2/partials/videoGrid.hbs'), function (template) {
      const html = template(videoGridData);
      $('#bc-video-grid').html(html);
      handleSorting();
      imageLoader.loadImages();
      self.updateCategoryNavBreadcrumbsAndSecondaryNav();
      if (videoGridData.videos.next) {
        const $loadMoreButton = $('.bc-load-more-button');
        $loadMoreButton.show();
        $loadMoreButton.data('next-page', videoGridData.videos.next);
        $loadMoreButton.on('click', self.loadMoreVideos.bind(self));
      }

    });
    let $videoBrowserTop = $('.bc-video-browser').offset().top;
    if (this.isMobile() || window.site.dynamicCustom.dynamicCategoryLoad === 'static') {
      $videoBrowserTop = 0;
    }
    window.scrollTo(0, $videoBrowserTop);
    $('.bc-sort-videos-mobile').on('shown.bs.modal', function () {
      self.styleBackdrop();
    });
    $('.bc-sort-videos-header').click(function () {
      self.stopPlayback();
    });
  }

  stopPlayback () {
    if (window.videojs && window.videojs.players &&
      window.videojs.players.performPlayer) {
      window.videojs.players.performPlayer.pause();
    }
  }

  appendVideos (data) {
    if (!data || !data.result || !data.result.items) {
      return;
    }

    const videos = data.result.items;

    const videoGridItemPath = window.bcGallery.getTemplatePath('/sites/marquee-v2/partials/videoGridItem.hbs');

    const self = this;
    this.loadTemplate(videoGridItemPath, function (template) {
      for (let i = 0; i < videos.length; i++) {
        if (videos[i]) {
          const contextData = {
            baseUrl: window.baseUrl,
            imageTranscoder: window.BCLS.imageTranscoder,
            category: window.category,
            query: window.query,
            subPath: window.subPath,
          };
          const handlebarContext = _.extend({}, videos[i], contextData);
          $('.bc-video-grid-items').append(template(handlebarContext));
        }
      }
      self.spinner.stop();
      imageLoader.loadImages();
      $('.bc-video-grid-items').fadeTo(0, 1);
      const count = data.result.count;
      const totalCount = data.result.totalCount;
      const end = data.result.end || data.result.count;
      let nextPageVideoCount;
      if (totalCount - end < count) {
        nextPageVideoCount = totalCount - end;
      } else if (totalCount !== end) {
        nextPageVideoCount = count;
      }
      const $loadMoreButton = $('.bc-load-more-button');
      if (data.result.next) {
        $loadMoreButton.text(window.translations['load-more'] +
          ' ( ' + nextPageVideoCount + ')');
        $loadMoreButton.data('next-page', data.result.next);
      } else {
        $loadMoreButton.hide();
      }

    });
  }

  updateCategoryNavBreadcrumbsAndSecondaryNav () {
    const self = this;

    /*
    const breadcrumbsData = {
      site: window.site,
      categories: window.categories,
      category: window.category,
      activePath: self.getActivePath(window.categories, window.category),
    };
    */

    // FOR MAIN CATEGORY NAV ------------------------------------------------
    // This will loop through all categories recursively
    // It will render each category's html and then return its rendered html to its parent
    function recurse (cat, template) {
      let htmlForChildren = [];

      if (cat.children) {
        htmlForChildren = cat.children.map(function (childCat) {
          return recurse(childCat, template);
        });
      }

      const html = template({
        category: cat,
        baseUrl: window.baseUrl,
        childHtml: htmlForChildren.length ? htmlForChildren : null,
        activeCategory: window.category,
        activePath: self.getActivePath(window.categories, window.category),
      });

      return html;
    }

    self.loadTemplate(window.bcGallery.getTemplatePath('/sites/marquee-v2/partials/categoryList.hbs'), function (topLevelTemplate) {
      self.loadTemplate(window.bcGallery.getTemplatePath('/sites/marquee-v2/partials/categoryListSub.hbs'), function (template) {
        const htmlForChildren = window.categories.map(function (cat) {
          return recurse(cat, template);
        });

        const html = topLevelTemplate({
          site: window.site,
          query: window.query,
          translations: window.translations,
          childHtml: htmlForChildren.length ? htmlForChildren : null,
        });

        $('#bc-category-list').html(html);
        $('#bc-category-list-mobile').html(html);
        $('.bc-sort-videos-mobile').on('shown.bs.modal', function () {
          self.styleBackdrop();
        });
        $('.bc-sort-videos-header').click(function () {
          self.stopPlayback();
        });
        //add listeners to the categories to use pushState on them for nav
        $('.gal-marquee-category-link').click(function (event) {
          if (window.site.dynamicCustom.dynamicCategoryLoad !== 'static' && !self.isMobile()) {
            // Replace the new category content dynamically.
            // Otherwise browser will just follow the link and reload the page.
            event.preventDefault();
            const target = $(event.target);
            const categorySlug = target.data('category-slug');
            const subPath = window.site.subPath || '';
            history.replaceState({}, document.title, '.'); //clear any hash from the URL (legacy from when marquee used hash routing)
            pushStateRouter.navigate(subPath + '/category/videos/' + categorySlug);
          }
        });
      });
    });

    // FOR SECONDARY NAV ------------------------------------------------
    // This will loop through all categories recursively
    // It will render each category's html and then return its rendered html to its parent
    function recurseSecondary (cat, template, parentIsActive, firstDisplayedChild) {
      let htmlForChildren = [];
      let html = '';

      if (cat.children) {
        htmlForChildren = cat.children.map(function (childCat) {
          if (cat.id === window.category.id) {
            //if parent is equal to active category, then set parent is active to true
            return recurseSecondary(childCat, template, true, true);
          } else {
            if (parentIsActive) {
              return recurseSecondary(childCat, template, true, false);
            } else {
              return recurseSecondary(childCat, template, false, false);
            }
          }
        });
      }

      html = template({
        category: cat,
        baseUrl: window.baseUrl,
        childHtml: htmlForChildren.length ? htmlForChildren : null,
        parentIsActive: parentIsActive,
        firstDisplayedChild: firstDisplayedChild,
      });

      return html;
    }

    self.loadTemplate(window.bcGallery.getTemplatePath('/sites/marquee-v2/partials/secondaryNav.hbs'), function (topLevelTemplate) {
      self.loadTemplate(window.bcGallery.getTemplatePath('/sites/marquee-v2/partials/secondaryNavList.hbs'), function (template) {
        const htmlForChildren = window.categories.map(function (cat) {
          return recurseSecondary(cat, template, false, false);
        });

        // doing this because htmlForChildren could end up being
        // an array of blank values
        let htmlIsContentless = true;
        for (const i in htmlForChildren) {
          if (htmlForChildren[i] !== '') {
            htmlIsContentless = false;
          }
        }

        let html = '';
        if (!htmlIsContentless) {
          html = topLevelTemplate({
            childHtml: htmlForChildren,
          });
        }

        $('#secondary-nav').html(html);
        bclsp1SecondaryNav.onTemplateUpdate();
      });
    });
  }

  //imitates functionality from activePath post assembler
  getActivePath (categories, category) {
    // will build a index of each category and its parent for reference
    function buildIndex (root, children) {
      return children.reduce((index, child) => {
        if (!child || typeof child !== 'object' || !child.id) {
          return index;
        }

        const currentRoot = {
          name: child.name,
          id: child.id,
          slug: child.slug,
        };
        const newIndex = { ...index, [child.id]: root };

        if (Array.isArray(child.children) && child.children.length > 0) {
          return { ...newIndex, ...buildIndex(currentRoot, child.children) };
        }

        return newIndex;
      }, {});
    }

    // will use the index built by buildIndex to create the active path
    function getPath (idKey, parentIndex) {
      return parentIndex[idKey] ? getPath(parentIndex[idKey].id, parentIndex).concat(parentIndex[idKey]) : [];
    }

    if (categories && category) {

      const parentIndex = buildIndex(null, categories);

      const activePath = getPath(category.id, parentIndex);
      // append the current category at the end of the list
      activePath.push({
        id: category.id,
        slug: category.slug,
        name: category.name,
      });

      return activePath;
    }
  }

  listenForImageLoads () {
    const $images = $('.navbar-brand img, .bc-fma-content-wrapper img');
    if ($images.length > 0) {
      $images.each(function () {
        $(this).on('load', function () {
          // resize calculations may rely on presence of images
          this.onWindowResize();
        });
      });
    }
  }

  listenForCategoryListCarets () {
    const self = this;
    $(document).on('click', '.category-list-parent-caret', function (e) {
      self.toggleChildCategoryList(e, this);
    });
    $(document).on('click', '.category-list-parent-caret-mobile', function (e) {
      self.toggleChildCategoryList(e, this);
    });
  }

  listenForHeaderCategoryClicks () {
    const self = this;
    $(document).on('click', '#bc-category-list li.parent-category span.header-only', function (e) {
      self.toggleChildCategoryList(e, $(this).siblings('.category-list-parent-caret'));
    });
    $(document).on('click', '#bc-category-list-mobile li.parent-category span.header-only', function (e) {
      self.toggleChildCategoryList(e, $(this).siblings('.category-list-parent-caret-mobile'));
    });
  }

  toggleChildCategoryList (e, element) {
    e.preventDefault();
    if (this.isMobile()) {
      $(element).find('.bc-chevron-down').toggle();
      $(element).find('.bc-chevron-up').toggle();
    } else {
      $(element).toggleClass('fa-chevron-down fa-chevron-right');
    }
    $(element).closest('.parent-category').find('.sub-category-list').eq(0).toggleClass('show hide');
  }

  preventClickFocus () {
    $('.navbar, .page-index, .page-detail, footer').on('mousedown', 'a', function (e) {
      e.preventDefault();
    });
  }

  //Read More/Less Buttons
  updateReadMore () {
    const $description = $('.video-description > p');
    const showReadMore = $description.prop('scrollHeight') > $description.height();
    let maxHeight;
    if (!this.isMobile()) {
      // disable the buttons on desktop and show full length.
      const $description = $('.video-description > p');
      $description.css({ display: 'block', height: 'auto' });
      $('.bc-read-more-container-mobile').toggle(false);
      $('.bc-read-less-container-mobile').toggle(false);
      return;
    } else {
      maxHeight = READMORE_HEIGHT;
      const showReadLess = !showReadMore && $description.height() > maxHeight;
      $('.bc-read-more-container-mobile').toggle(showReadMore);
      $('.bc-read-less-container-mobile').toggle(showReadLess);
    }
  }

  styleBackdrop () {
    const $backdrop = $('.modal-backdrop');
    if ($backdrop.length) {
      $('.modal-backdrop').css('opacity', '1');
      $('.modal-backdrop').css('background-color', 'black');
    }
  }

  handleSearch (event) {
    event.preventDefault();
    const query = $(event.target).find('.bc-video-search-form-value').val();
    if (!query) {
      return;
    }

    if (this.isMobile()) {
      this.stopPlayback();
    }

    history.replaceState({}, '', `?q=${query}`);

    this.loadVideos({
      q: query,
    });
    event.preventDefault();
  }

  isMobile () {
    const screenWidth = $(window).width();
    return screenWidth <= BREAKPOINT_MOBILE;
  }
}

class BCLSP1secondaryNav {
  init () {
    const self = this;
    $(document).click(function (event) {
      if (!$(event.target).closest('li.top-level').length) {
        self.closeAllDropdows();
      }
    });
  }

  onTemplateUpdate () {
    const $secondaryNavTriggerDropdowns = $('#secondary-nav ul li .trigger-dropdown');
    const self = this;
    $secondaryNavTriggerDropdowns.click(function () {
      const $this = $(this);
      self.closeAllDropdows($this.closest('.top-level'));

      $this.siblings('ul').toggleClass('show-dropdown hide-dropdown');
      $this.parent().toggleClass('active-dropdown');
    });
  }
  closeAllDropdows (excludedElement) {
    $('#secondary-nav > ul > li.active-dropdown').each(function () {
      const $this = $(this);

      // closeAllDropdowns gets called on every .trigger-dropdown click
      // we exclude a top level dropdown that is the parent of a dropdown being activated
      if (excludedElement && $this[0] === excludedElement[0]) {
        return;
      }

      $this.children('ul').toggleClass('show-dropdown hide-dropdown');
      $this.removeClass('active-dropdown');
    });
  }
}

const bclsp1 = new BCLSP1();
const bclsp1SecondaryNav = new BCLSP1secondaryNav();
const pushStateRouter = new Grapnel({ pushState: true });
const hashRouter = new Grapnel();

// this is used by templateScripts.hbs
global.BCLS.setupPlayers = setupPlayers;

const imageLoader = new BCImageLoader({
  transcoder: global.BCLS.imageTranscoder,
});

$(document).ready(function () {

  // always load images in 16:9 ratio, helps IE 11
  imageLoader.ignoreHeight = true;
  imageLoader.loadImages();

  bclsp1.preventClickFocus();
  bclsp1.listenForImageLoads();
  bclsp1.listenForCategoryListCarets();
  bclsp1.listenForHeaderCategoryClicks();
  bclsp1.setScrollbarWidth(bclsp1.determineScrollbarWidth());
  bclsp1SecondaryNav.init();
  bclsp1SecondaryNav.onTemplateUpdate();
  bclsp1.onWindowResize();

  videoFinisher.onNextVideo(function () {
    utils.nextVideo();
  });

  const subPath = window.site.subPath || '';
  hashRouter.get('category/videos/:category/:page', function (req) {
    bclsp1.loadVideos(req.params);
  });
  pushStateRouter.get(subPath + '/category/videos/:category/:page', function (req) {
    bclsp1.loadVideos(req.params);
  });
  hashRouter.get('category/videos/:category', function (req) {
    bclsp1.loadVideos(req.params);
  });
  pushStateRouter.get(subPath + '/category/videos/:category', function (req) {
    bclsp1.loadVideos(req.params, true);
  });
  hashRouter.get('search/:q/:page', function (req) {
    bclsp1.loadVideos(req.params);
  });
  pushStateRouter.get(subPath + '/search/:q/:page', function (req) {
    bclsp1.loadVideos(req.params);
  });
  hashRouter.get('search/:q', function (req) {
    bclsp1.loadVideos(req.params);
  });
  pushStateRouter.get(subPath + '/search/:q', function (req) {
    bclsp1.loadVideos(req.params);
  });

  bclsp1.updateCategoryNavBreadcrumbsAndSecondaryNav();

  $(document).on('submit', '.bc-video-search-form', bclsp1.handleSearch.bind(bclsp1));

  handleSorting();

  $('.bc-read-more-container-mobile').on('click', function () {
    const $description = $('.video-description > p');
    $description.height($description.prop('scrollHeight'));
    $description.css('-webkit-line-clamp', 'unset');
    $('.bc-read-more-container-mobile').toggle(false);
    $('.bc-read-less-container-mobile').toggle(true);
  });

  $('.bc-read-less-container-mobile').on('click', function () {
    const $description = $('.video-description > p');
    $description.height(READMORE_HEIGHT + 'px');
    $description.css({ '-webkit-line-clamp': '3' });
    $('.bc-read-more-container-mobile').toggle(true);
    $('.bc-read-less-container-mobile').toggle(false);
  });

  $('.bc-load-more-button').on('click', bclsp1.loadMoreVideos.bind(bclsp1));
});

$(window).resize(function () {
  bclsp1.onWindowResize();
});
